import config from "@/config.js";

const store = {};

store.state = {
  navigation: null,
  peasant: null,
  workshop: null,
  shops: null,
};

/**
 *
 * Navigation functions
 */
store.getNavigationContent = function(locale, callback) {
  const endpoint = "/pages/1379";

  fetch(`${config.api.url}${config.api.path}${endpoint}?lang=${locale}`)
    .then((res) => res.json())
    .then((res) => {
      this.state.navigation = res.acf;

      this.formatNavigationRes();

      if (callback) {
        callback();
      }
    });
};

store.formatNavigationRes = function() {
  for (
    let i = 0;
    i < this.state.navigation.header_sculptures_links.length;
    i++
  ) {
    let link = this.state.navigation.header_sculptures_links[i].url;
    if (link.indexOf("?") > 0) {
      this.state.navigation.header_sculptures_links[i].url = link.substring(
        0,
        link.indexOf("?")
      );
    }
  }

  // for(let i = 0; i < this.state.navigation.header_chocolate_links.length; i++)
  // {
  //     let link = this.state.navigation.header_chocolate_links[i].url
  //     this.state.navigation.header_chocolate_links[i].url = link.substring(0, link.indexOf('?'))
  // }
};

/**
 *
 *  Chocolate stories functions
 */
store.getStories = function(locale, callback) {
  console.log(`${config.api.url}${config.api.path}/pages/413?lang=${locale}`);
  const endpoints = [
    {
      label: "peasant",
      url: `${config.api.url}${config.api.path}/pages/101?lang=${locale}`,
    },
    {
      label: "workshop",
      url: `${config.api.url}${config.api.path}/pages/103?lang=${locale}`,
    },
    {
      label: "shops",
      url: `${config.api.url}${config.api.path}/pages/413?lang=${locale}`,
    },
  ];

  const allFetch = endpoints.map((endpoint) => {
    return fetch(endpoint.url)
      .then((res) => res.json())
      .then((res) => {
        this.state[endpoint.label] = res.acf;
        return res.acf;
      });
  });

  Promise.all(allFetch).then(() => {
    if (callback) {
      callback(this.content);
    }
  });
};

export default store;
