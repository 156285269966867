<template>
  <div
    class="about"
    :class="{ 'scroll-wrapper': !$root.isMobile }"
    v-if="contentLoaded"
  >
    <div class="scroll-elem" :class="{ 'js-scroll-elem': !$root.isMobile }">
      <NavSculptures />

      <section class="about__quote--first">
        <CanvasBackground scene="lines" type="vertical" />
        <div class="container">
          <div class="about__quote--first__title" v-if="content.cover_title">
            <h2
              v-if="$root.isMobile"
              class="about__quote--first__title__elem title--sculptures reduced-fs"
              v-html="content.cover_title"
            ></h2>
            <ParallaxeVerticalElem v-else :amount="0.5">
              <h2
                class="about__quote--first__title__elem title--sculptures reduced-fs"
                v-html="content.cover_title"
              ></h2>
            </ParallaxeVerticalElem>
          </div>

          <p
            class="about__quote--first__description text-sculptures"
            v-html="content.cover_description"
            v-if="content.cover_description"
          ></p>
        </div>
      </section>
      <section class="about__illustration">
        <LazyImg
          v-if="$root.isMobile"
          class="about__illustration__elem"
          :url="content.first_fullscreen_media.image.url"
          :alt="content.first_fullscreen_media.image.alt"
          :width="content.first_fullscreen_media.image.width"
          :height="content.first_fullscreen_media.image.height"
        />
        <VideoBox
          :data="content.first_fullscreen_media.video"
          v-else-if="
            content.first_fullscreen_media.video &&
              content.first_fullscreen_media.video
          "
        />
        <ParallaxeVerticalElem v-else :isInner="true" :amount="0.1">
          <LazyImg
            class="about__illustration__elem"
            :url="content.first_fullscreen_media.image.url"
            :alt="content.first_fullscreen_media.image.alt"
            :width="content.first_fullscreen_media.image.width"
            :height="content.first_fullscreen_media.image.height"
          />
        </ParallaxeVerticalElem>
      </section>
      <section class="about__quote--second">
        <CanvasBackground scene="rain" line="true" type="vertical" />
        <div class="container">
          <div class="about__quote--second__title" v-if="content.second_title">
            <h2
              v-if="$root.isMobile"
              class="about__quote--second__title__elem title--sculptures reduced-fs"
              v-html="content.second_title"
            ></h2>
            <ParallaxeVerticalElem v-else :amount="0.5">
              <h2
                class="about__quote--second__title__elem title--sculptures reduced-fs"
                v-html="content.second_title"
              ></h2>
            </ParallaxeVerticalElem>
          </div>

          <p
            class="about__quote--second__description text-sculptures"
            v-html="content.second_description"
            v-if="content.second_description"
          ></p>
        </div>
      </section>

      <div class="about__lines--two">
        <CanvasBackground scene="lines" type="vertical" />

        <section class="about__third-block">
          <div
            class="about__third-block__description"
            v-if="content.second_image_description"
          >
            <p
              class="about__third-block__description__text text-sculptures"
              v-html="content.second_image_description"
            ></p>
          </div>
          <LazyImg
            v-if="$root.isMobile"
            class="about__third-block__img"
            :url="content.second_image.url"
            :alt="content.second_image.alt"
            :width="content.second_image.width"
            :height="content.second_image.height"
          />
          <ParallaxeVerticalElem
            v-else
            class="about__third-block__img"
            :isInner="true"
            :amount="0.1"
          >
            <LazyImg
              :url="content.second_image.url"
              :alt="content.second_image.alt"
              :width="content.second_image.width"
              :height="content.second_image.height"
            />
          </ParallaxeVerticalElem>
        </section>

        <div class="container">
          <p
            class="about__fourth-block text-sculptures"
            v-html="content.third_description"
            v-if="content.third_description"
          ></p>
        </div>

        <div class="container">
          <div class="about__fifth-block__title" v-if="content.third_title">
            <h2
              v-if="$root.isMobile"
              class="about__fifth-block__title__elem title--sculptures reduced-fs"
              v-html="content.third_title"
            ></h2>
            <ParallaxeVerticalElem v-else :amount="0.5">
              <h2
                class="about__fifth-block__title__elem title--sculptures reduced-fs"
                v-html="content.third_title"
              ></h2>
            </ParallaxeVerticalElem>
          </div>
        </div>
      </div>

      <LazyImg
        v-if="$root.isMobile"
        class="about__fifth-block__img"
        :url="content.third_image.url"
        :alt="content.third_image.alt"
        :width="content.third_image.width"
        :height="content.third_image.height"
      />
      <ParallaxeVerticalElem
        v-else
        class="about__fifth-block__img"
        :isInner="true"
        :amount="0.1"
      >
        <LazyImg
          :url="content.third_image.url"
          :alt="content.third_image.alt"
          :width="content.third_image.width"
          :height="content.third_image.height"
        />
      </ParallaxeVerticalElem>

      <div class="about__fifth-block__text container">
        <div
          class="about__fifth-block__description"
          v-if="content.fourth_paragraphs && content.fourth_paragraphs.length"
        >
          <p
            v-for="(item, index) in content.fourth_paragraphs"
            :key="index"
            v-html="item.paragraph"
            class="text-sculptures"
          ></p>
        </div>

        <div class="about__quote-last" v-if="content.fourth_title">
          <h2
            v-if="$root.isMobile"
            class="about__quote-last__elem title--sculptures reduced-fs"
            v-html="content.fourth_title"
          ></h2>
          <ParallaxeVerticalElem v-else :amount="0.5">
            <h2
              class="about__quote-last__elem title--sculptures reduced-fs"
              v-html="content.fourth_title"
            ></h2>
          </ParallaxeVerticalElem>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import API from "@/mixins/API.js";
import Page from "@/mixins/Page.js";
import SmoothScrollVerticalMixin from "@/mixins/SmoothScrollVerticalMixin.js";

import NavSculptures from "@/components/NavSculptures.vue";
import Footer from "@/components/Footer.vue";
import CanvasBackground from "@/components/CanvasBackground.vue";
import LazyImg from "@/components/LazyImg.vue";
import ParallaxeVerticalElem from "@/components/ParallaxeVerticalElem.vue";
import VideoBox from "@/components/VideoBox.vue";

export default {
  name: "SculpturesAbout",

  mixins: [API, Page, SmoothScrollVerticalMixin],

  components: {
    LazyImg,
    CanvasBackground,
    Footer,
    NavSculptures,
    ParallaxeVerticalElem,
    VideoBox,
  },

  created() {
    this.fetchLang("/pages/243", () => {
      this.contentLoaded = true;

      this.onMounted();

      // we have 1500ms during transition
      window.setTimeout(() => {
        if (!this.$root.isMobile) {
          this.SmoothScrollVerticalMixin_init();
        }
      }, 100);
    });
  },
};
</script>

<style lang="stylus" scoped>
.about
  color $white

  background $black

  @media(max-width $breakpoints.md)
    overflow-x hidden

.about__quote--first
  position relative
  overflow hidden

  padding-top 800px
  padding-bottom 320px

  @media(max-width $breakpoints.xl)
    padding-top 700px

  @media(max-width $breakpoints.lg)
    padding-top 600px

  @media(max-width $breakpoints.md)
    padding-top 420px
    padding-bottom 100px

.about__quote--first__title
  margin-bottom 230px

  @media(max-width $breakpoints.md)
    margin-bottom 80px

.about__quote--first__title__elem
  grid-col(1, 6)

  @media(max-width $breakpoints.md)
    width 100%
    margin 0

.about__quote--first__description
  grid-col(5, 2)
  max-width $sculpture-paragraph-mwidth

  letter-spacing 0em
  opacity 0.6

  @media(max-width $breakpoints.md)
    width 100%
    margin 0

.about__illustration
  width 100%

.about__illustration__elem
  display block

  width 100%

.about__quote--second
  position relative
  overflow hidden

  padding-top 290px
  padding-bottom 530px

  @media(max-width $breakpoints.xl)
    padding-bottom 450px

  @media(max-width $breakpoints.lg)
    padding-bottom 380px

  @media(max-width $breakpoints.md)
    padding-top 120px
    padding-bottom 120px

.about__quote--second__title
  margin-bottom 400px

  @media(max-width $breakpoints.xl)
    margin-bottom 350px

  @media(max-width $breakpoints.lg)
    margin-bottom 300px

  @media(max-width $breakpoints.md)
    margin-bottom 0
    padding-bottom 80px

.about__quote--second__title__elem
  grid-col(3.5, 4.5)

  @media(max-width $breakpoints.md)
    width 100%
    margin 0

.about__quote--second__description
  grid-col(5, 2.5)
  max-width $sculpture-paragraph-mwidth

  letter-spacing 0em
  opacity 0.4

  @media(max-width $breakpoints.md)
    width 100%
    margin 0

.about__lines--two
  position relative

.about__third-block
  display flex

  padding-bottom 500px

  @media(max-width $breakpoints.xl)
    padding-bottom 450px

  @media(max-width $breakpoints.lg)
    padding-bottom 380px

  @media(max-width $breakpoints.md)
    flex-direction column-reverse

    padding-bottom 80px

.about__third-block__description, .about__third-block__img
  width 50%

  @media(max-width $breakpoints.md)
    width 100%

.about__third-block__description__text
  margin-left 20%
  padding-top 430px
  width 50%
  max-width $sculpture-paragraph-mwidth

  opacity 0.3

  @media(max-width $breakpoints.md)
    width 100%
    margin-left 0
    padding 100px 10% 0 10%

.about__fourth-block
  grid-col(5, 2.5)
  max-width $sculpture-paragraph-mwidth
  padding-bottom 315px

  opacity 0.3

  @media(max-width $breakpoints.md)
    width 100%
    margin 0
    padding-bottom 120px

.about__fifth-block__text
  position relative
  padding-top 200px

  @media(max-width $breakpoints.md)
    padding-top 100px

  &:after
    content ''
    position absolute
    top 0
    left 50%
    width 1px
    height 100%
    background-color #6b6b6b
    opacity 0.57

    @media(max-width $breakpoints.md)
      display none

.about__fifth-block__title
  padding-bottom 280px

  @media(max-width $breakpoints.md)
    padding-bottom 100px

.about__fifth-block__title__elem
  grid-col(1, 4.5)

  @media(max-width $breakpoints.md)
    width 100%
    margin 0

.about__fifth-block__img
  width calc((100% / 9) * 8 + 50px)
  height auto
  margin-left calc(100% / 9)
  // margin-bottom 200px

  @media(max-width $breakpoints.md)
    width 100%
    margin-left 0
    // margin-bottom 100px

.about__fifth-block__description
  grid-col(1, 2.5)
  max-width $sculpture-paragraph-mwidth
  padding-bottom 230px

  opacity 0.3

  @media(max-width $breakpoints.md)
    width 100%
    margin 0
    padding-bottom 100px

  p
    padding-bottom 100px

    @media(max-width $breakpoints.md)
      padding-bottom 80px

    &:last-child
      padding-bottom 0

.about__quote-last
  padding-bottom 440px

  @media(max-width $breakpoints.md)
    padding-bottom 100px

.about__quote-last__elem
  grid-col(3, 5)

  @media(max-width $breakpoints.md)
    width 100%
    margin 0
</style>
