import { render, staticRenderFns } from "./MenuHome.vue?vue&type=template&id=a2b92b0c&"
import script from "./MenuHome.vue?vue&type=script&lang=js&"
export * from "./MenuHome.vue?vue&type=script&lang=js&"
import style0 from "./MenuHome.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports