<template>
  <div
    class="sculptures"
    :class="{ 'scroll-wrapper': !$root.isMobile }"
    v-if="contentLoaded"
  >
    <div class="scroll-elem" :class="{ 'js-scroll-elem': !$root.isMobile }">
      <NavSculptures />

      <section class="sculptures__hero" :class="{ container: $root.isMobile }">
        <CanvasBackground scene="lines" type="vertical" />

        <div :class="{ container: !$root.isMobile }">
          <p
            class="sculptures__hero__description text-sculptures"
            v-html="content.acf.cover_description"
          ></p>
        </div>
      </section>

      <section class="sculptures__list" :class="{ container: $root.isMobile }">
        <div :class="{ container: !$root.isMobile }">
          <div class="sculptures__list__filter">
            <!-- <p class="sculptures__list__filter__text">{{ $t('sculptures.list.filter') }}</p>
            <span class="sculptures__list__filter__elem"></span> -->
          </div>

          <div class="sculptures__list__inner">
            <template v-for="(item, index) in content.sculptures">
              <SculptureCardHorizontal
                v-if="item.acf.display === 'x' && !$root.isMobile"
                class="sculptures__list__sculpture"
                :key="index"
                :id="item.id"
                :number="item.acf.number"
                :title="item.acf.name"
                :dimensions="item.acf.dimensions"
                :foundry="item.acf.foundry"
                :date="item.acf.date"
                :material="item.acf.material"
                :image="item.acf.images[0].image"
                :theme="item.acf.theme"
              />
              <SculptureCard
                v-else
                class="sculptures__list__sculpture"
                :key="index"
                :id="item.id"
                :number="item.acf.number"
                :title="item.acf.name"
                :dimensions="item.acf.dimensions"
                :foundry="item.acf.foundry"
                :date="item.acf.date"
                :material="item.acf.material"
                :image="item.acf.images[0].image"
                :theme="item.acf.theme"
              />
            </template>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  </div>
</template>

<script>
import config from "@/config.js";

import API from "@/mixins/API";
import Page from "@/mixins/Page.js";
import SmoothScrollVerticalMixin from "@/mixins/SmoothScrollVerticalMixin.js";

import NavSculptures from "@/components/NavSculptures.vue";
import Footer from "@/components/Footer.vue";
import SculptureCard from "@/components/SculptureCard.vue";
import SculptureCardHorizontal from "@/components/SculptureCardHorizontal.vue";
import CanvasBackground from "@/components/CanvasBackground.vue";

export default {
  name: "Sculptures",

  mixins: [API, Page, SmoothScrollVerticalMixin],

  components: {
    SculptureCard,
    SculptureCardHorizontal,
    CanvasBackground,
    Footer,
    NavSculptures,
  },

  created() {
    this.currentPage = 1;
    this.loadingAmount = 99;

    const endpoints = [
      {
        url: `/pages/241?lang=${this.$i18n.locale}`,
        label: null,
      },
      {
        url: `/sculptures?per_page=${this.loadingAmount}&page=${this.currentPage}&lang=${this.$i18n.locale}`,
        label: "sculptures",
      },
    ];

    this.fetchMultiple(endpoints, () => {
      this.contentLoaded = true;

      this.checkLoadedAmount();
    });
  },

  methods: {
    fetchCompleted() {
      this.onMounted();

      window.setTimeout(() => {
        if (!this.$root.isMobile) {
          this.SmoothScrollVerticalMixin_init();
        }
      }, 100);
    },

    checkLoadedAmount() {
      // Check if max sculptures per page is reach, so maybe there are other pages to fetch
      if (
        this.content.sculptures.length ===
        this.loadingAmount * this.currentPage
      ) {
        this.currentPage += 1;
        this.fetchAdditionalContent();
      }
      // if not, mount page
      else {
        this.fetchCompleted();
      }
    },

    fetchAdditionalContent() {
      const url = `/sculptures?per_page=${this.loadingAmount}&page=${this.currentPage}&lang=${this.$i18n.locale}`;

      fetch(`${config.api.url}${config.api.path}${url}`).then(
        (res) => {
          res.json().then((res) => {
            this.content.sculptures = this.content.sculptures.concat(res);
            this.checkLoadedAmount();
          });
        },
        () => {
          this.fetchCompleted();
        }
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.sculptures {
  @media (max-width: $breakpoints.md) {
    overflow-x: hidden;
  }
}

.sculptures__hero {
  position: relative;
  overflow: hidden;
  padding-top: 400px;
  padding-bottom: 290px;
  background: $black;

  @media (max-width: $breakpoints.md) {
    padding-top: 420px;
    padding-bottom: 100px;
  }
}

.sculptures__hero__description {
  grid-col(5.5, 2.5);
  max-width: $sculpture-paragraph-mwidth;
  color: $white;
  opacity: 0.2;

  @media (max-width: $breakpoints.md) {
    width: 100%;
    margin: 0;
  }
}

.sculptures__list {
  position: relative;
  padding-bottom: 250px;

  @media (max-width: $breakpoints.md) {
    padding-bottom: 0px;
  }

  &:after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: $grey;

    @media (max-width: $breakpoints.md) {
      display: none;
    }
  }
}

.sculptures__list__filter {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 45px;
  padding-bottom: 50px;
  grid-col(0, 7);
  margin: 0 auto;

  @media (max-width: $breakpoints.md) {
    width: 100%;
    margin: 0;
  }
}

.sculptures__list__filter__elem {
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  border: 2px solid $black;
}

.sculptures__list__sculpture {
  position: relative;
  z-index: 2;
  padding-bottom: 100px;
}
</style>