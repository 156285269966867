<template>
    <div>
        <router-link :to="to" class="menu-home-button" @mousemove.native="mouseMoveHandler" v-if="to">
            <slot />
        </router-link>
        <a :href="href" class="menu-home-button" @mousemove.native="mouseMoveHandler" v-if="href">
            <slot />
        </a>
    </div>
</template>

<script>
import EventHub from '@/utils/EventHub.js'
import math from '@/webgl/utils/math';

const BACKGROUND_IMAGE_WIDTH = 149;
// const BACKGROUND_IMAGE_WIDTH = 56;
const BACKGROUND_IMAGE_HEIGHT = 103;
// const BACKGROUND_IMAGE_HEIGHT = 55;

export default {
    name: 'MenuHomeButton',

    props: [
        'to',
        'href'
    ],

    created()
    {
        this.dimensions = { width: 0, height: 0 }
        this.parentDimensions = { width: 0, height: 0 }
        this.mousePosition = { x: 0, y: 0 }
        this.offset = { x: 0, y: 0 }
        this.backgroundPosition = [
            {
                rotation: 0,
                current: { x: 0, y: 0 },
                target: { x: 0, y: 0 }
            },
            {
                rotation: 0,
                current: { x: 0, y: 0 },
                target: { x: 0, y: 0 }
            }
        ]
    },

    mounted()
    {
        this.resize()
        this.setupEventListeners()
    },

    destroyed()
    {
        this.removeEventListeners()
    },

    methods: {
        setupEventListeners()
        {
            EventHub.$on('application:on-tick', this.tickHandler)
            EventHub.$on('window:on-resize', this.resizeHandler)
            window.addEventListener('mousemove', this.mouseMoveHandler)
        },

        removeEventListeners()
        {
            EventHub.$off('application:on-tick', this.tickHandler)
            EventHub.$on('window:on-resize', this.resizeHandler)
            window.removeEventListener('mousemove', this.mouseMoveHandler)
        },

        update()
        {
            this.updateMouseOffset()
            this.updateBackgroundPosition()
        },

        updateMouseOffset()
        {
            const cbr = this.$el.getBoundingClientRect()
            const elementX = cbr.left
            const elementY = cbr.top
            const centerX = elementX + cbr.width * 0.5
            const centerY = elementY - this.offset.y + this.parentDimensions.height * 0.5

            const angle = math.angleBetweenPoints({ x: centerX, y: centerY }, this.mousePosition)
            const distance = math.distance({ x: centerX, y: centerY }, this.mousePosition)

            const maxRadius = 50
            const radius = math.clamp(distance, 0, maxRadius)

            const x = this.dimensions.width * 0.5 - BACKGROUND_IMAGE_WIDTH * 0.5
            const y = -this.offset.y + this.parentDimensions.height * 0.5 - BACKGROUND_IMAGE_HEIGHT * 0.5

            this.backgroundPosition[0].rotation += 0.007;
            const rotationRadius = 50;
            const rotationX = rotationRadius * Math.cos(this.backgroundPosition[0].rotation);
            const rotationY = rotationRadius * Math.sin(this.backgroundPosition[0].rotation);

            // BG #1
            this.backgroundPosition[0].target.x = x + radius * 0.3 * Math.cos(angle) + rotationX
            this.backgroundPosition[0].target.y = y + radius * 0.3 * Math.sin(angle) + rotationY

            // BG #2
            this.backgroundPosition[1].target.x = x + radius * 0.4 * Math.cos(-angle) - rotationX
            this.backgroundPosition[1].target.y = y + (50 + radius) * 0.4 * Math.sin(-angle) - rotationY
        },

        updateBackgroundPosition()
        {
            // BG #1
            this.backgroundPosition[0].current.x = this.lerp(this.backgroundPosition[0].current.x, this.backgroundPosition[0].target.x, 0.04)
            this.backgroundPosition[0].current.y = this.lerp(this.backgroundPosition[0].current.y, this.backgroundPosition[0].target.y, 0.04)

            // BG #2
            this.backgroundPosition[1].current.x = this.lerp(this.backgroundPosition[1].current.x, this.backgroundPosition[1].target.x, 0.01)
            this.backgroundPosition[1].current.y = this.lerp(this.backgroundPosition[1].current.y, this.backgroundPosition[1].target.y, 0.01)

            this.$el.children[0].style.backgroundPosition = `${this.backgroundPosition[0].current.x}px ${this.backgroundPosition[0].current.y}px`
            this.$el.children[0].style.backgroundPosition += `, ${this.backgroundPosition[1].current.x}px ${this.backgroundPosition[1].current.y}px`
        },

        lerp(v0, v1, t)
        {
            return v0 * (1 - t) + v1 * t
        },

        resize()
        {
            const parentElement = this.$el.parentElement;
            this.dimensions.width = this.$el.offsetWidth;
            this.dimensions.height = this.$el.offsetHeight;
            this.parentDimensions.width = parentElement.parentElement.offsetHeight;
            this.parentDimensions.height = parentElement.parentElement.offsetHeight;
            this.offset.x = parentElement.offsetLeft
            this.offset.y = parentElement.offsetTop;
        },

        /**
         * Handlers
         */
        mouseMoveHandler(e)
        {
            this.mousePosition.x = e.clientX
            this.mousePosition.y = e.clientY
        },

        tickHandler()
        {
            this.update()
        },

        resizeHandler()
        {
            this.resize()
        }
    }
}
</script>

<style lang="stylus">
.menu-home-button
    display block

    font-family 'Overpass'
    font-weight 300
    font-size 34px
    text-transform uppercase
    letter-spacing 0.07em
    line-height 34px

    -webkit-text-fill-color transparent
    background-clip text

    // background-color: #0f2926;
    background-color rgba(15, 173, 137, 0.5)
    background-image url('../assets/images/home-menu-blob.png'), url('../assets/images/home-menu-blob.png')
    background-repeat no-repeat

    transform-origin center right

    transition background-color 0.6s ease-out

    .is-ru &
        font-family 'Oswald'

    .is-ja & 
        font-weight 500

    @media(max-width $breakpoints.md)
        font-size 16px
        line-height 24px

.menu-home-button:hover
    background-color: rgba(15, 173, 137, 1);

</style>