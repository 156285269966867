<template>
  <router-link
    :to="`/${$i18n.locale}/sculptures/sculptures/${id}`"
    class="sculpture-card--horizontal"
  >
    <div class="sculpture-card--horizontal__image">
      <LazyImg
        v-if="$root.isMobile"
        class="sculpture-card--horizontal__image__elem"
        :url="image.url"
        :alt="image.alt"
        :width="image.width"
        :height="image.height"
      />
      <ParallaxeVerticalElem v-else :isInner="true" :amount="0.1">
        <LazyImg
          class="sculpture-card--horizontal__image__elem"
          :url="image.url"
          :alt="image.alt"
          :width="image.width"
          :height="image.height"
        />
      </ParallaxeVerticalElem>
      <div
        :class="[
          'sculpture-card--horizontal__content',
          { 'is-white': theme === 'dark' },
        ]"
      >
        <p class="sculpture-card__infos__number">{{ number }}</p>
        <div class="sculpture-card__infos__text">
          <h3 class="sculpture-card__infos__title">{{ title }}</h3>
          <div class="sculpture-card__infos__description">
            <p
              class="sculpture-card__infos__material"
              v-if="material"
              v-html="material"
            ></p>
            <p
              class="sculpture-card__infos__date"
              v-if="date"
              v-html="date"
            ></p>
            <p
              class="sculpture-card__infos__dimensions"
              v-if="dimensions"
              v-html="dimensions"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ParallaxeVerticalElem from "@/components/ParallaxeVerticalElem.vue";
import LazyImg from "@/components/LazyImg.vue";

export default {
  name: "SculptureCardHorizontal",

  components: {
    ParallaxeVerticalElem,
    LazyImg,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    number: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    dimensions: {
      type: String,
    },
    date: {
      type: String,
    },
    foundry: {
      type: String,
    },
    material: {
      type: String,
    },
    image: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
    },
  },
};
</script>

<style lang="stylus">
.sculpture-card--horizontal {
  display: block;
  grid-col(0, 8);
  margin: 0 auto;
}

@media (max-width: $breakpoints.md) {
  width: 100%;
  margin-left: 0;
}

.sculpture-card--horizontal__image {
  position: relative;
  width: 100%;
}

.sculpture-card--horizontal__image__elem {
  display: block;
  width: 100%;
}

.sculpture-card--horizontal__content {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 6%;
  height: 100%;
  width: 88%;
  margin: 0 auto;

  &.is-white {
    color: #fff;
  }

  .sculpture-card__infos__number {
    margin-top: 60px;
  }

  .sculpture-card__infos__description {
    margin-bottom: 60px;
  }
}

.sculpture-card--horizontal__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>