import { render, staticRenderFns } from "./Sculptures.vue?vue&type=template&id=577d234a&scoped=true&"
import script from "./Sculptures.vue?vue&type=script&lang=js&"
export * from "./Sculptures.vue?vue&type=script&lang=js&"
import style0 from "./Sculptures.vue?vue&type=style&index=0&id=577d234a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "577d234a",
  null
  
)

export default component.exports